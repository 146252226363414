<template>
	<div>
			<div class="grid grid-cols-1 md:grid-cols-2 mb-4">
		<div class="col-span-1 flex items-center flex-wrap">
			<h2 class="text-2xl font-bold mr-3">
				{{ tab | sentenceCase }} Transactions
			</h2>
		</div>
		<div class="col-span-1">
		</div>
	</div>
			<div class="border border-solid border-blue-200">
			<div class="flex flex-col">
					<div class="duration-300">
					<datatable
							:data="settlements.data"
							:columns="columns"
							:fillable="false"
							:loading="settlements.loading"
							ref="table"
					/>
					</div>
			</div>
			</div>
	</div>
</template>
<script>
export default {
	props: ["id"],
	data() {
		return {
			settlements: this.$options.resource([]),
			columns: [
				{
					name: "user_id",
					th: "User ID",
					render: (settlement) => settlement?.user_id,
				},
				{
					name: "reference",
					th: "Reference",
					render: (settlement) => settlement?.reference,
				},
				{
					name: "payout_id",
					th: "Payout ID",
					render: (settlement) => settlement?.payout_id,
				},
				{
					name: "description",
					th: "Description",
					render: (settlement) => settlement?.description,
				},
				{
					name: "amount",
					th: "Amount",
					render: (settlement) => '₦'+ settlement?.amount,
				},
				{
					name: 'created_at',
					th: 'Created At',
					render: (settlement) =>
						this.$moment(settlement?.created_at).format('dddd, MMMM Do YYYY, h:mm:ss a'),
				},
				{
          name: "status",
          th: "Status",
          render: (settlement) => {
            return (settlement?.status === 'success') ?
              `<div class="badge badge-green-soft-outline px-7">
                ${settlement?.status}
              </div>`
                :
                `<div class="badge badge-orange-soft-outline px-7">
									${settlement?.status}
              </div>`
          }
        },
			],
		};
	},
	mounted() {
		this.paidSettlement();
	},
	methods: {
		async paidSettlement() {
			this.settlements.loading = true;
			await this.sendRequest("admin.merchants.payouts.merchantTransactions", this.$route.params.merchantId, {
				success: (response) => {
					this.settlements.data = response.data.data.data;
				},
				error: (error) => {
					console.log(error);
				},
			});
			this.settlements.loading = false;
		},
	},
};
</script>